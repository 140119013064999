<template>
  <v-chip
    :color="color"
    small
  >
    {{ statementStatesStateEnum[state] }}
  </v-chip>
</template>

<script>
import { mapGetters } from 'vuex';

const STATES_COLORS = {
  draft: 'grey',
  ready: 'light-blue',
  posted: 'success',
  empty: 'grey darken-2',
  error: 'error',
  payed: 'success darken-2',
  rebuild: 'grey',
};

export default {
  name: 'IrStatementStateLabel',

  props: {
    state: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters('enums', ['statementStatesStateEnum']),

    color() {
      return STATES_COLORS[(this.statementStatesStateEnum[this.state] || '').toLowerCase()];
    },
  },
};
</script>
