<template>
  <v-menu
    close-delay="100"
    nudge-left="30"
    open-on-hover
    left
  >
    <template #activator="{ on }">
      <v-btn
        icon
        small
        class="mr-3"
        color="success"
        v-on="on"
      >
        <v-icon>
          file_download
        </v-icon>
      </v-btn>
    </template>

    <v-btn-toggle
      :value-comparator="() => false"
      mandatory
    >
      <v-btn
        v-for="(format, i) in fileFormats"
        :key="i"
        small
        text
        @click="downloadFile(format)"
      >
        {{ format }}
      </v-btn>
    </v-btn-toggle>
  </v-menu>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { StatementsApiService } from '../_services';

export default {
  name: 'IrStatementDownloader',

  props: {
    statementId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      fileFormats: ['pdf', 'csv', 'xlsx'],
    };
  },

  methods: {
    downloadFile(format) {
      this.wrapToLoadingFn({
        req: StatementsApiService.getStatementFileURL.bind({}, {
          statementId: this.statementId,
          format,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: url => window.open(url, '_blank'),
      });
    },

    wrapToLoadingFn,
  },
};
</script>
