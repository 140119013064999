<template>
  <v-layout>
    <vbt-table
      :items="items"
      :headers="headers"
      :page-size="pageSize"
    >
      <template #amount="{ item }">
        {{ formatNumber(item.amount) }}
        / {{ formatNumber(item.taxAmount) }}
        / {{ formatNumber(item.amountWithTax) }}
      </template>

      <template #dates="{ item }">
        <v-layout
          class="py-1"
          column
        >
          {{ formatDisplayDate(item.dateCreated) }}

          <v-divider class="my-1" />

          {{ formatDisplayDate(item.dateFrom, { dateFormat: 'yyyy-MM-dd' }) }}
          - {{ formatDisplayDate(item.dateTo, { dateFormat: 'yyyy-MM-dd' }) }}
        </v-layout>
      </template>

      <template #state="{ item: { state } }">
        <ir-statement-state-label :state="state" />
      </template>

      <template #invoices="{ item }">
        <v-btn
          :disabled="!item.invoices && !item.invoices.length"
          color="info"
          small
          icon
          @click="showInvoices(item)"
        >
          <v-icon>
            remove_red_eye
          </v-icon>
        </v-btn>
      </template>

      <template #actions="{ item }">
        <v-layout>
          <ir-statement-downloader :statement-id="item.id" />

          <ir-statements-actions
            :statement-id="item.id"
            v-on="$listeners"
          />
        </v-layout>
      </template>
    </vbt-table>

    <ir-statements-invoices-viewer ref="statementsInvoicesViewer" />
  </v-layout>
</template>

<script>
import { formatDisplayDate, formatNumber } from '@helpers';

import IrStatementsInvoicesViewer from './IrStatementsInvoicesViewer.vue';
import IrStatementStateLabel from './IrStatementStateLabel.vue';
import IrStatementDownloader from './IrStatementDownloader.vue';
import IrStatementsActions from './IrStatementsActions.vue';

const headers = Object.freeze([
  { text: 'Account', value: 'accountName' },
  { text: 'Number', value: 'number' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: 'Created/Period Date', value: 'dates' },
  { text: 'State', value: 'state' },
  { text: 'Invoices', value: 'invoices' },
  { text: '', value: 'actions', width: '30px' },
]);

export default {
  name: 'IrStatementsList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  components: {
    IrStatementsInvoicesViewer,
    IrStatementStateLabel,
    IrStatementDownloader,
    IrStatementsActions,
  },

  data() {
    return {
      fileFormats: ['pdf', 'csv', 'xlsx'],

      headers,
    };
  },

  methods: {
    showInvoices({ accountName, number, invoices }) {
      const title = `${accountName} ${number}`;

      this.$refs.statementsInvoicesViewer.$_openDialog(title, invoices);
    },

    formatDisplayDate,
    formatNumber,
  },
};
</script>
