<template>
  <v-layout column>
    <ir-statements-search @search="getStatements" />

    <vbt-content-box
      :loading="loading"
      :loading-error="loadingError"
      :no-content="!statements.length"
      title="Statements"
    >
      <ir-statements-list
        :items="statements"
        @refresh="getStatements"
      />

      <vbt-pagination
        v-model="pageNum"
        :items-count="pagedItemsCount"
      />
    </vbt-content-box>
  </v-layout>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { usePagination } from '@mixins/factories';

import { dataLoadingState } from '@mixins';

import { StatementsApiService } from '../_services';

import { IrStatementsSearch, IrStatementsList } from '../_components';

const paginationOptions = { itemsName: 'statements' };

export default {
  name: 'IrStatements',

  components: {
    IrStatementsList,
    IrStatementsSearch,
  },

  mixins: [
    usePagination(paginationOptions),
    dataLoadingState,
  ],

  data() {
    return {
      statements: [],

      refreshParams: {},
    };
  },

  methods: {
    getStatements(params) {
      this.$_setDataLoadingPending();

      if (params) {
        this.$set(this, 'refreshParams', params);
      }

      const p = params || this.refreshParams;

      this.wrapToLoadingFn({
        req: StatementsApiService.getStatements.bind({}, {
          ...p,
          from: p.dates ? p.dates[0] : null,
          to: p.dates ? `${p.dates[1]} 23:59:59` : null,
        }),
        loadingFlagName: dataLoadingState.$_meta.LOADING_FLAG_NAME,
        onSuccess: response => this.$set(this, 'statements', response),
        onReject: e => this.$_setDataLoadingError(e),
      });
    },

    wrapToLoadingFn,
  },
};
</script>
