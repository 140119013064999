var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('vbt-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"page-size":_vm.pageSize},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" / "+_vm._s(_vm.formatNumber(item.taxAmount))+" / "+_vm._s(_vm.formatNumber(item.amountWithTax))+" ")]}},{key:"dates",fn:function(ref){
var item = ref.item;
return [_c('v-layout',{staticClass:"py-1",attrs:{"column":""}},[_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.dateCreated))+" "),_c('v-divider',{staticClass:"my-1"}),_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.dateFrom, { dateFormat: 'yyyy-MM-dd' }))+" - "+_vm._s(_vm.formatDisplayDate(item.dateTo, { dateFormat: 'yyyy-MM-dd' }))+" ")],1)]}},{key:"state",fn:function(ref){
var state = ref.item.state;
return [_c('ir-statement-state-label',{attrs:{"state":state}})]}},{key:"invoices",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.invoices && !item.invoices.length,"color":"info","small":"","icon":""},on:{"click":function($event){return _vm.showInvoices(item)}}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-layout',[_c('ir-statement-downloader',{attrs:{"statement-id":item.id}}),_c('ir-statements-actions',_vm._g({attrs:{"statement-id":item.id}},_vm.$listeners))],1)]}}])}),_c('ir-statements-invoices-viewer',{ref:"statementsInvoicesViewer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }