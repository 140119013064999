<template>
  <vbt-dialog
    v-model="dialog"
    :title="title"
    hide-submit-btn
    width="80%"
  >
    <ir-invoices-list
      :items="invoices"
      paged
    />
  </vbt-dialog>
</template>

<script>
import { IrInvoicesList } from '@modules/costs/invoices';

export default {
  name: 'IrStatementsInvoicesViewer',

  components: {
    IrInvoicesList,
  },

  data() {
    return {
      invoices: [],
      title: 'Invoices',

      dialog: false,
    };
  },

  methods: {
    $_openDialog(title, invoices) {
      this.title = `${title} invoices`;
      this.invoices = invoices;
      this.dialog = true;
    },
  },
};
</script>
