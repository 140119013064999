<template>
  <v-layout>
    <v-menu
      close-delay="100"
      nudge-left="30"
      nudge-top="15"
      open-on-hover
      left
    >
      <template #activator="{ on }">
        <v-btn
          icon
          small
          v-on="on"
        >
          <v-icon>
            more_vert
          </v-icon>
        </v-btn>
      </template>

      <v-layout column>
        <v-btn
          small
          @click="rebuildDialog = true"
        >
          Rebuild
        </v-btn>
        <v-btn
          small
          @click="markPayed"
        >
          Mark Payed
        </v-btn>
      </v-layout>
    </v-menu>

    <vbt-dialog
      v-model="rebuildDialog"
      submit-btn-name="Rebuid"
      width="320px"
      @close="resetRebuildParams"
      @submit="rebuild"
    >
      <v-checkbox
        v-model="rebuildNotify"
        label="Send email notification to account"
      />
    </vbt-dialog>
  </v-layout>
</template>

<script>
import { wrapToLoadingFn } from '@helpers';

import { StatementsApiService } from '../_services';

export default {
  name: 'IrStatementsActions',

  props: {
    statementId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      rebuildDialog: false,
      rebuildNotify: false,
    };
  },

  methods: {
    rebuild() {
      this.wrapToLoadingFn({
        req: StatementsApiService.rebuildStatement.bind(null, {
          statementId: this.statementId,
          notify: this.rebuildNotify,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Rebuild successfully started');
          this.resetRebuildParams();
          this.rebuildDialog = false;
        },
      });
    },

    markPayed() {
      this.$VBlackerTheme.alert.warning({
        text: 'Are you sure to mark this statement as Payed?',
      }, () => this.wrapToLoadingFn({
        req: StatementsApiService.markStatementAsPayed.bind(null, {
          statementId: this.statementId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully marked');
          this.$emit('refresh');
        },
      }));
    },

    resetRebuildParams() {
      this.rebuildNotify = false;
    },

    wrapToLoadingFn,
  },
};
</script>
