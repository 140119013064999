const LOADING_FLAG_NAME = 'loading';

export const dataLoadingState = {
  data() {
    return {
      [LOADING_FLAG_NAME]: false,
      loadingError: { show: false, text: '' },
    };
  },

  methods: {
    $_setDataLoadingPending() {
      this[LOADING_FLAG_NAME] = true;
      this.loadingError.show = false;
      this.loadingError.text = '';
    },

    $_setDataLoadingSucceed() {
      this[LOADING_FLAG_NAME] = false;
      this.loadingError.show = false;
      this.loadingError.text = '';
    },

    $_setDataLoadingError(errorMsg) {
      this[LOADING_FLAG_NAME] = false;
      this.loadingError.show = true;
      this.loadingError.text = errorMsg;
    },
  },

  $_meta: {
    LOADING_FLAG_NAME,
  },
};
